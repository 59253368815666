import pop from 'compop';
import './polyfills';

// Components
import Accordion from './components/accordion';
import FeaturesCarousel from './components/features-carousel';
import Form from './components/form';
import HeroCarousel from './components/hero-carousel';
import ImageComparison from './components/image-comparison';
import ImageComparisonsCarousel from './components/image-comparisons-carousel';
import Input from './components/input';
import InspirationCarousel from './components/inspiration-carousel';
import InstallerAdminInstallers from './components/installer-admin/installers';
import InstallerAdminProductsForm from './components/installer-admin/products-form';
import InstallerAdminProjectsForm from './components/installer-admin/projects-form';
import InstallerReviews from './components/installer-reviews';
import InstallerReviewsCarousel from './components/installer-reviews-carousel';
import Main from './components/main';
import Modal from './components/modal';
import ProductDetails from './components/product-details';
import ProductDetailsModal from './components/product-details-modal';
import ProductsSlider from './components/products-slider';
import ProductTypesSlider from './components/product-types-slider';
import ProjectsSlider from './components/projects-slider';
import RoomCarousel from './components/room-carousel';
import RoomsSlider from './components/rooms-slider';
import StatsChart from './components/stats-chart';
import VimeoVideo from './components/vimeo-video';
import YouTubeVideo from './components/youtube-video';
import Tooltip from './components/tooltip';

const SITE_HANDLE = 'skylightspecialistca';

// Define map of component handles to component classes
/* eslint-disable quote-props */
const classMap = {
    'accordion': Accordion,
    'features-carousel': FeaturesCarousel,
    'form': Form,
    'hero-carousel': HeroCarousel,
    'image-comparison': ImageComparison,
    'image-comparisons-carousel': ImageComparisonsCarousel,
    'input': Input,
    'inspiration-carousel': InspirationCarousel,
    'installer-admin-installers': InstallerAdminInstallers,
    'installer-admin-products-form': InstallerAdminProductsForm,
    'installer-admin-projects-form': InstallerAdminProjectsForm,
    'installer-reviews': InstallerReviews,
    'installer-reviews-carousel': InstallerReviewsCarousel,
    'main': Main,
    'modal': Modal,
    'product-details': ProductDetails,
    'product-details-modal': ProductDetailsModal,
    'products-slider': ProductsSlider,
    'product-types-slider': ProductTypesSlider,
    'projects-slider': ProjectsSlider,
    'room-carousel': RoomCarousel,
    'rooms-slider': RoomsSlider,
    'stats-chart': StatsChart,
    'vimeo-video': VimeoVideo,
    'youtube-video': YouTubeVideo,
};
/* eslint-enable quote-props */

// Define all actions/commands that components pub/sub
const actions = {
    loadModal: 'LOAD_MODAL',
    openModal: 'OPEN_MODAL',
    closeModal: 'CLOSE_MODAL',
    lockScroll: 'LOCK_SCROLL',
    unlockScroll: 'UNLOCK_SCROLL',
    setInputValue: 'SET_INPUT_VALUE',
};

// Event handler functions
function handleDOMConentLoaded() {
    const scaffold = window[SITE_HANDLE];

    // Functionality for after components initialize
    function cb() {
        // ...
    }

    // Call component constructors
    pop({ scaffold, classMap, actions, cb });


    Tooltip.init();
}

// Add event listeners
document.addEventListener('DOMContentLoaded', handleDOMConentLoaded);
