export default class {
    constructor({
        /* eslint-disable no-unused-vars */
        id,
        videoContainerId
    }) {

        this.onPlayerReady = this.onPlayerReady.bind(this)
        this.onPlayerStateChange = this.onPlayerStateChange.bind(this)

        // Keep track of available players incase we need to control them other components
        window.youtubePlayers = window.youtubePlayers || [];

        let maxReadyTries = 3;
        let readyTries = 0;
        let readyInterval = setInterval(() => {

            if (!window.youTubeReady && readyTries < maxReadyTries) {
                readyTries += 1;
                return;
            }

            if (readyTries >= maxReadyTries) {
                clearInterval(readyInterval);
                return;
            }

            if (window.youTubeReady) {
                this.setupPlayer(document.getElementById(videoContainerId));
                clearInterval(readyInterval);
            }

        }, 500);
    }

    setupPlayer(element) {

        const videoUrl = element.dataset.videoUrl
        const videoParts = videoUrl.match(/(?:v=|embed\/)([^?&/]+)/)

        if (!videoParts[1]) {
            console.warn('Failed to parse YouTube Video ID from URL', videoUrl);
            return;
        }

        const videoId = videoParts[1];

        let player = new window.YT.Player(element.id, {
            videoId: videoId,
            playerVars: {
                'playsinline': 1
            },
            events: {
                'onReady': this.onPlayerReady,
                'onStateChange': this.onPlayerStateChange
            }
        });

        window.youtubePlayers.push(player)
    }

    /* eslint-disable class-methods-use-this */
    onPlayerReady(event) {
        event.target.playVideo();
    }

    /* eslint-disable class-methods-use-this */
    onPlayerStateChange(event) {
        console.log(event);
    }
}
