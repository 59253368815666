import { createPopper } from '@popperjs/core';

export default {

    init: () => {
        [...document.querySelectorAll('[data-tooltip-content]')].forEach((button, i) => {
        
            const toolTip = document.createElement('div');

            toolTip.setAttribute('id', `tooltip-${i}`)
            toolTip.setAttribute('class', 'tooltip-container')
            toolTip.setAttribute('data-popper-reference-hidden', '')
            toolTip.setAttribute('data-popper-escaped', '')
            toolTip.textContent = button.dataset.tooltipContent

            document.body.appendChild(toolTip);

            const popper = createPopper(button, toolTip, {
                placement: 'right',
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, 10],
                        },
                    },
                ],
            });

            (function(btn, tt, ppr) {
                btn.addEventListener('mouseenter', () => {
                    tt.setAttribute('data-show', '')
                    ppr.update()
    
                })

                btn.addEventListener('mouseleave', () => {
                    tt.removeAttribute('data-show')
                    ppr.update()

                })
            })(button, toolTip, popper);
        })
    }
}